<template>
  <div>
    <div class="lt_panel" :style="ltStyle">
      <div class="main">
        <el-row style="height:100%">
          <el-col v-for="(item, index) in ltData" :key="index" :span="item.span" :style="{ height: item.height }">
            <div class="lt_item" :class="index == 0 ? 'lt_item_1' : ''">
              <div :style="{ color: item.color }">
                <p class="count" :class="index == 0 ? 'color-text' : ''">{{ item.count }}</p>
                <p class="title">{{ item.title }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <chart v-for="(item, index) in chartList" :key="index" :chartName="item.name" :chartType="item.type" :chartStyle="item.style" :chartData="item.data" @click="chartClick"></chart>
    <!-- <div class="absolute" :style="g6Style">
      <l-antv-g6></l-antv-g6>
    </div> -->
    <rb-list v-if="!config" :config="config" :monitorData="monitorData"></rb-list>
  </div>
</template>

<script>
import Chart from './chart'
import RBList from './rb_list'
// import LAntvG6 from '@/lgh/components/antv/G6'
export default {
  props: {
    monitorData: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    }
  },
  components: { chart: Chart, 'rb-list': RBList },
  data() {
    return {
      chartList: [],
      g6Style: null,
      ltStyle: null,
      ltData: []
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    monitorData() {
      this.chartList = []
      setTimeout(() => {
        this.init()
      }, 50)
    }
  },
  methods: {
    init() {
      this.ltData = []
      this.ltData.push({
        span: 24,
        height: '25%',
        title: '总提出数',
        count: this.monitorData.ltData.c1,
        color: ''
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '员工人数',
        count: this.monitorData.ltData.c2,
        color: 'rgb(88,217,249)'
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '已完成',
        count: this.monitorData.ltData.c3,
        color: 'rgb(43,130,29)'
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '未整改',
        count: this.monitorData.ltData.c4,
        color: 'rgb(250,110,118)'
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '整改中',
        count: this.monitorData.ltData.c5,
        color: 'rgb(230,182,0)'
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '临时管控',
        count: this.monitorData.ltData.c6,
        color: 'rgb(193,46,52)'
      })
      this.ltData.push({
        span: 12,
        height: '25%',
        title: '无效',
        count: this.monitorData.ltData.c7,
        color: 'rgb(100,100,100)'
      })
      this.ltStyle = {
        left: this.config.space + 'px',
        top: this.config.space + 'px',
        height: this.config.unit + 'px',
        width: this.config.unit + 'px'
      }
      // // 左1
      // this.chartList.push({
      //   name: 'matterTypeData',
      //   type: 'pie',
      //   style: {
      //     left: this.config.space + 'px',
      //     top: this.config.space + 'px',
      //     height: this.config.unit + 'px',
      //     width: this.config.unit + 'px'
      //   },
      //   data: {
      //     title: '检查类型',
      //     pie: {
      //       radius: '50%'
      //       // roseType: 'area'
      //     },
      //     data: this.monitorData.matterTypeData
      //   }
      // })
      // 左2
      this.chartList.push({
        name: 'matterTypeData',
        type: 'pie',
        style: {
          left: this.config.space + 'px',
          top: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '检查类型',
          pie: {
            radius: ['20%', '55%']
            // roseType: 'area'
          },
          data: this.monitorData.matterTypeData
        }
      })
      // 右1
      this.chartList.push({
        name: 'matterStatusData',
        type: 'pie',
        style: {
          right: this.config.space + 'px',
          top: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '上报状态',
          color: ['rgb(153,153,153)', 'rgb(230,182,0)', 'rgb(255,110,118)', 'rgb(43,130,29)'],
          pie: {
            radius: '50%',
            roseType: 'radius'
          },
          // data: [
          //   { name: '正常', value: 18 },
          //   { name: '即将到期', value: 4 },
          //   { name: '已过期', value: 1 }
          // ]
          data: this.monitorData.matterStatusData
        }
      })
      // 右2
      this.chartList.push({
        name: 'effectStatusData',
        type: 'pie',
        style: {
          right: this.config.space + 'px',
          top: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.unit + 'px',
          width: this.config.unit + 'px'
        },
        data: {
          title: '整改状态',
          color: ['rgb(250,110,118)', 'rgb(230,182,0)', 'rgb(43,130,29)', 'rgb(193,46,52)'],
          pie: {
            radius: ['20%', '55%'],
            roseType: 'radius'
          },
          data: this.monitorData.effectStatusData
        }
      })
      // 左3
      this.chartList.push({
        name: 'x_monthData',
        type: 'bar',
        style: {
          left: this.config.space + 'px',
          bottom: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: (this.config.width - this.config.space * 3) / 2 + 'px'
        },
        data: {
          title: this.monitorData.year ? this.monitorData.year + '年统计' : '年份统计',
          color: ['rgb(0,103,187)', 'rgb(255,110,118)', 'rgb(232,142,37)', 'rgb(255,0,0)', 'rgb(43,130,29)'],
          data: this.monitorData.x_monthData
        }
      })
      // 右3
      this.chartList.push({
        name: 'x_departData',
        type: 'bar',
        style: {
          right: this.config.space + 'px',
          bottom: this.config.space + 'px',
          height: this.config.unit + 'px',
          width: (this.config.width - this.config.space * 3) / 2 + 'px'
        },
        data: {
          title: this.monitorData.isEGroup ? '企业监控' : '部门监控',
          color: ['rgb(250,110,118)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.x_departData
        }
      })

      // 中间
      this.chartList.push({
        name: '123123',
        type: 'bar',
        style: {
          top: this.config.head.height + this.config.space * 2 + 'px',
          left: this.config.unit + this.config.space * 2 + 'px',
          height: this.config.height - this.config.head.height - this.config.unit - this.config.space * 4 + 'px',
          width: this.config.width - this.config.unit * 2 - this.config.space * 4 + 'px'
        },
        data: {
          title: '隐患统计',
          color: ['rgb(43,130,29)', 'rgb(230,182,0)', 'rgb(193,46,52)'],
          data: this.monitorData.c_departData
        }
      })

      // this.g6Style = {
      //   top: this.config.head.height + this.config.space * 2 + 'px',
      //   left: this.config.unit + this.config.space * 2 + 'px',
      //   height: this.config.height - this.config.head.height - this.config.unit - this.config.space * 4 + 'px',
      //   width: this.config.width - this.config.unit * 2 - this.config.space * 4 + 'px'
      // }
    },
    chartClick(a, b) {
      if (a === 'carNature') {
        this.$site.showCarList({ carNature: b.data.code })
      } else if (a === 'carStatus') {
        this.$site.showCarList({ carStatus: b.data.code })
      } else if (a === 'insurance') {
        if (b.seriesName.indexOf('交强险') !== -1) {
          this.$site.showCarList({ carStatus: 1, jqxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        } else if (b.seriesName.indexOf('商业险') !== -1) {
          this.$site.showCarList({ carStatus: 1, syxInsuranceMonth: b.dataIndex + 1, noStatus7: true })
        }
      } else if (a === 'yearlyInspect') {
        this.$site.showCarList({ carStatus: 1, yearlyInspectMonth: b.dataIndex + 1, noStatus7: true })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lt_panel {
  position: absolute;

  .main {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    background-color: #282839;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
  }
}

.lt_item {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p.count {
    font-size: 30px;
    font-weight: bold;
  }
  p.title {
    font-size: 12px;
  }
}
.lt_item_1 {
  p.count {
    font-size: 40px;
  }
}
.color-text {
  text-align: center;
  font-size: 40px;
  /* 设置背景透明色 */
  background-image: -webkit-linear-gradient(left, blue, #66ffff 10%, #cc00ff 20%, #cc00cc 30%, #ccccff 40%, #00ffff 50%, #ccccff 60%, #cc00cc 70%, #cc00ff 80%, #66ffff 90%, blue 100%);
  /* 改变background-size是为了让动画动起来 */
  background-size: 200% 100%;
  /* 按照文字拆分背景 */
  -webkit-background-clip: text;
  /* 将字体设置成透明色 */
  -webkit-text-fill-color: transparent;
  /* 启用动画 */
  animation: masked-animation 6s linear infinite;
}

@keyframes masked-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
