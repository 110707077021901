<template>
  <section class="l-content full relative page-bg">
    <head-panel v-if="monitorData" :monitor-data="monitorData" :config="config" :condition="condition" @change="change"></head-panel>
    <chart-container v-if="monitorData" :monitor-data="monitorData" :config="config"></chart-container>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import ChartContainer from './statistic/chartContainer'
import HeadPanel from './statistic/headPanel'
export default {
  computed: {
    ...mapState(['userInfo', 'setting'])
  },
  name: 'CarMonitor',
  components: { 'chart-container': ChartContainer, 'head-panel': HeadPanel },
  data() {
    return {
      monitorData: null,
      config: null,
      condition: {
        year: new Date().getFullYear(),
        enterpriseCode: '',
        eGroupCode: ''
      },
      markerList: []
    }
  },
  created() {
    this.config = {
      height: document.documentElement.clientHeight - 60,
      width: document.documentElement.clientWidth - 210,
      space: 5,
      head: {
        height: 50
      }
    }
    this.config.unit = (this.config.height - this.config.space * 4) / 3

    this.condition.eGroupCode = this.userInfo.eGroupCode
    if (this.userInfo.eGroupCode && this.userInfo.eGroupCode === this.userInfo.enterpriseCode) {
      this.condition.enterpriseCode = ''
    } else {
      this.condition.enterpriseCode = this.userInfo.enterpriseCode
    }

    this.getMonitorData()
  },
  methods: {
    async getMonitorData() {
      this.monitorData = await this.$lgh.get('api/enterprise/safely/matter/monitor', ['enterpriseCode', this.condition])
    },
    change(data) {
      this.condition.year = data.year
      this.condition.enterpriseCode = data.enterpriseCode
      this.condition.eGroupCode = data.eGroupCode
      this.getMonitorData()
    }
  }
}
</script>
<style lang="less" scoped>
.page-bg {
  background-color: #111;
}
.m_block {
  position: relative;
  .brandlogo {
    position: absolute;
    right: 8px;
    top: 0px;
    height: 80px;
    width: 80px;
  }
}
</style>
