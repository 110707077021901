<template>
  <div v-if="dataList" class="rb-list-main" :style="mainStyle">
    <div style="padding:5px 10px">
      <div class="c1" :style="cStyle">
        <ul class="rb-list-ul">
          <li v-for="(item, index) in dataList" :key="index">
            <p class="p1">{{ config.unit }}{{ item.adviceType }}</p>
            <p class="p2">{{ item.adviceTitle }}</p>
            <p class="p3">{{ item.adviceContent }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    monitorData: {
      type: Object,
      default: null
    },
    config: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mainStyle: null,
      cStyle: null,
      dataList: null,
      tick: 0
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.dataList = this.monitorData.rb_10_list
      this.mainStyle = {
        right: this.config.space + 'px',
        bottom: this.config.space + 'px',
        height: this.config.unit + 'px',
        width: (this.config.width - this.config.space * 3) / 2 + 'px'
      }

      // var count = parseInt((this.config.unit - 10) / 35)
      // setTimeout(() => {
      //   this.start()
      // }, 3000)
    },
    start() {
      if (this.tick > this.dataList.length - 5) {
        this.tick = 0
        this.cStyle = {
          transition: null,
          transform: 'translate(0,-' + this.tick * 35 + 'px)'
        }
      }
      this.tick++
      this.cStyle = {
        transform: 'translate(0,-' + this.tick * 35 + 'px)'
      }
      setTimeout(() => {
        this.start()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.rb-list-main {
  position: absolute;
  border-radius: 10px;
  color: #fff;
  background-color: rgba(20, 87, 121, 0.5);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  div.c1 {
    transition: all ease-in 1000ms;
    overflow: hidden;
  }
}

.rb-list-ul {
  margin: 0;
  padding: 0;
  li {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: left;
    p {
      padding: 0px 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    p.p1 {
      width: 80px;
    }
    p.p2 {
      width: 150px;
    }
    p.p3 {
      flex: 1;
    }
  }
}

.rb-list_table {
  width: 100%;
  color: #fff;
  text-align: left;
  tr {
    line-height: 35px;
  }
  tr.head {
    background-color: rgba(35, 67, 85, 0.5);
  }
  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
